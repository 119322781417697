import { IUpdateFramePriceRequest } from '../interfaces/update-frame-price-request';

export class UpdateFramePriceRequest {
    /** Frame Id */
    public frameId: number;
    /** Retail price */
    public retailPrice: number;
    /**
     * Initializes instance of the UpdateFramePriceRequest class.
     * @param frameId Frame Id
     * @param retailPrice Retail price
     */
    constructor(updateFramePriceRequest: IUpdateFramePriceRequest) {
        this.frameId = updateFramePriceRequest.FrameId;
        this.retailPrice = updateFramePriceRequest.RetailPrice;
    }
}
