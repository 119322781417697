import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { AppConfigurationService, AppFeatureService } from '@pm/core/configuration';
import { IntegrationsService } from '@pm/core/integrations';
import { DialogHelper, NavLinkDirective } from '@pm/layout';
import { SecurityService } from '@pm/core/security';
import { MatDialog } from '@angular/material/dialog';
import { ChangeLocationDialog } from '../../dialogs/change-location/change-location.dialog';

/**
 * Component for the toolbar section of the header.
 */
@Component({
  selector: 'pm-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    NavLinkDirective,
    TranslateModule
  ]
})

/**
 * Toolbar component
 */
export class ToolbarComponent implements AfterViewInit {

  /** Has day close role? */
  public hasDayCloseRole = this.config.dailyRoles.hasDayCloseRole;
  /** Has day open role? */
  public hasDayOpenRole = this.config.dailyRoles.hasDayOpenRole;
  /** Has payment types role? */
  public hasPaymentTypesRole = this.config.dailyRoles.hasPaymentTypesRole;
  /** Is EHR enabled? */
  public isEhrEnabled = this.config.isEhrEnabled;
  /** Is payment authentication API enabled? */
  public isPaymentAuthApi = this.feature.isPaymentAuthApi;
  /** Is till enabled? */
  public isTillEnabled = this.feature.isTillEnabled;
  /** Is training enabled? */
  public isTrainingEnabled = this.config.isTrainingEnabled;
  /** Is go live pending? */
  public isTrainingPendingLive = this.config.isTrainingPendingLive;
  /** KPI/Commissions view. */
  readonly kpiView = this.config.kpiView;
  /** Office external id. */
  public officeExternalId = this.config.officeExternalId;
  /** URL for support knowledge base. */
  public supportKnowledgeBaseUrl = this.config.supportKnowledgeBaseUrl;
  /** Training company id. */
  public trainingCompanyId = this.config.trainingCompanyId;
  /** Username. */
  public username = this.config.username;
  /** Helper class for dialogs. */
  private dialogHelper = new DialogHelper();
  
  /** name of setting used to present change office dialog on load if flag is set */
  readonly showSelectOfficeDialog:string = 'ShowSelectOfficeDialog';

  /**
   * Initializes instance of the HeaderToolbarComponent class.
   * @param config Application configuration service
   * @param integrations Integrations service
   * @param feature Application feature service
   * @param security Security service
   */
  constructor(
    private config: AppConfigurationService,
    private integrations: IntegrationsService,
    private feature: AppFeatureService,
    private security: SecurityService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }
  
  ngAfterViewInit(): void {
    this.checkForSelectOfficeAfterLogin();
  }

  /** see if we need to present the change location dialog */
  checkForSelectOfficeAfterLogin() : void {
    if (window.localStorage.getItem(this.showSelectOfficeDialog) == 'true')
      {
        setTimeout(() => this.openChangeLocationModal(new Event(this.showSelectOfficeDialog)), 500);
      }
  }

  /** Label for the user menu. */
  get userMenuLabel(): string {
    const truncatedName = this.username && this.username.length > 9 ? this.username.slice(0, 9) + '...' : this.username;

    return `${truncatedName} in ${this.officeExternalId}`;
  }

  /**
   * Launch EHR.
   * @param event Dom event.
   */
  launchEhr(event: Event): void {
    event.preventDefault();
    this.integrations.launchEhr();
  }

  /**
   * Logout.
   */
  logout(): void {
    this.security.logout();
  }

  /**
   * Toggle training mode.
   * @param event DOM event.
   */
  toggleTraining(event: Event): void {
    event.preventDefault();
    this.config.toggleTraining();
  }

  /**
   * Walk Me Through launcher.
   * @param event DOM event
   */
  walkMeThroughLauncher(event: Event): void {
    event.preventDefault();
    this.config.walkMeThroughLauncher();
  }

  /**
   * Open change location modal.
   * 
   * @param event  DOM event
   */
  openChangeLocationModal(event: Event) : void {
    event.preventDefault();
    const dialogRef = this.dialog.open(ChangeLocationDialog, this.dialogHelper.large({ infoMessage: this.messageForDialog(event.type) }, false));

    dialogRef.afterClosed().subscribe(result => {
        this.changeLocationDialogClose(event);
        console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * get infoMessage for dialog based on the Event type if needed
   * @param eventType string that
   * @returns message or null
   */
  messageForDialog(eventType:string) : string | null
  {
    const message = eventType == this.showSelectOfficeDialog ? this.translate.instant('MESSAGES.SELECT_OFFICE_AFTER_LOGIN', { officeNum: this.config.officeExternalId }) : null;
    return message;
  }

  /**
   * Set showSelectOfficeDialog to false, 
   * only if the change location modal is closed by the user.
   * 
   * @param event  DOM event
   */
  changeLocationDialogClose(event: Event) : void
  {
    if (event.type == this.showSelectOfficeDialog)
    {
      window.localStorage.setItem(this.showSelectOfficeDialog, 'false');
    }
  }
}
