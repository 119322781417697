import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sort } from '@angular/material/sort';

import { ISearchResults } from '@pm/core';
import { Accessory } from '../classes/accessory';
import { CatalogItem } from '../classes/catalog-item';
import { Frame } from '../classes/frame';
import { FrameDetail } from '../classes/frame-detail';
import { IAccessory } from '../interfaces/accessory';
import { IFrame } from '../interfaces/frame';
import { IFrameDetail } from '../interfaces/frame-detail';
import { ICatalogItem } from '../interfaces/catalog-item';
import { ICatalogResults } from '../interfaces/catalog-results';
import { UpdateFramePriceRequest } from '../classes/update-frame-price-request';

/**
 * Service which makes requests to the catalog API.
 */
@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  /** Default sort state for accessories. */
  public defaultAccessoriesSort: Sort = { active: 'ItemName', direction: 'asc' };
  /** Default sort state for frames. */
  public defaultFramesSort: Sort = { active: 'Style', direction: 'asc' };

  /** Cached data from API. */
  private cache: IOrderPricingCache = {};
  /** API controller. */
  private controller = 'ProductCatalog';

  /**
   * Initializes instance of the CatalogService class.
   * @param http HTTP client
   */
  constructor(private http: HttpClient) { }

  /**
   * Find accessories.
   * @param searchText Search text
   * @param skip Number of iutems to skip
   * @param take Number of items to take
   * @param sort Sort state
   * @returns Accessory search results
   */
  async findAccessories(searchText: string | null, skip = 0, take = 10, sort = this.defaultAccessoriesSort): Promise<ISearchResults<Accessory>> {
    return new Promise<ISearchResults<Accessory>>(resolve => {
      const url = `${this.controller}/SearchAccessories?start=${skip}&length=${take}&orderBy=${sort.active}&orderDir=${sort.direction}&searchText=${encodeURIComponent(searchText ?? '')}`;
      this.http.get<ICatalogResults<IAccessory> | null>(url).subscribe({
        next: data => resolve(data ? { Items: data.data.map(x => new Accessory(x)), TotalAvailable: data.recordsFiltered }
          : { Items: [], TotalAvailable: 0 }),
        error: () => resolve({ Items: [], TotalAvailable: 0 })
      });
    });
  }

  /**
   * Find frames.
   * @param searchText Search text
   * @param skip Number of iutems to skip
   * @param take Number of items to take
   * @param sort Sort state
   * @returns Frame search results
   */
  async findFrames(searchText: string | null, active: boolean, skip = 0, take = 10, sort = this.defaultFramesSort): Promise<ISearchResults<Frame>> {
    return new Promise<ISearchResults<Frame>>(resolve => {
      const url = `${this.controller}/SearchFrames?start=${skip}&length=${take}&orderBy=${sort.active}&orderDir=${sort.direction}&searchText=${encodeURIComponent(searchText ?? '')}&showOnHandOnly=false&IncludeUnMapped=${!active}`;
      this.http.get<ICatalogResults<IFrame> | null>(url).subscribe({
        next: data => resolve(data ? { Items: data.data.map(x => new Frame(x)), TotalAvailable: data.recordsFiltered }
          : { Items: [], TotalAvailable: 0 }),
        error: () => resolve({ Items: [], TotalAvailable: 0 })
      });
    });
  }

  /**
   * Get frame detail.
   * @param itemId Item id
   * @returns Frame detail
   */
  async getFrameDetail(itemId: number): Promise<FrameDetail | null> {
    return new Promise<FrameDetail | null>(resolve => {
      const url = `${this.controller}/GetFrameDetails?itemId=${itemId}`;
      this.http.get<{ data: IFrameDetail } | null>(url).subscribe({
        next: data => {
          if (data?.data) {
            resolve(new FrameDetail(data.data));
          } else {
            resolve(null);
          }
        },
        error: () => resolve(null)
      });
    });
  }
/**
 * Add frame that is not mapped to the company.
 * @param request 
 * @returns 
 */
  async updateFrameRetailPrice(request: UpdateFramePriceRequest): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const url = `${this.controller}/UpdateEgFrameRetailPrice`;
      this.http.put<boolean>(url, request).subscribe({
        next: success => resolve(success),
        error: () => resolve(false)
      });
    });
  }

  /**
   * Get miscellaneous fees.
   * @returns Miscellaneous fees
   */
  async getMiscellaneousFees(): Promise<CatalogItem[]> {
    return this.getCatalogItems('GetMiscellaneousFees');
  }

  /**
   * Get shipping methods.
   * @returns Shipping methods
   */
  async getShippingMethods(): Promise<CatalogItem[]> {
    return this.getCatalogItems('GetShippingMethods');
  }

  /**
   * Get repair fees.
   * @returns Repair fees
   */
  async getRepairFees(): Promise<CatalogItem[]> {
    return this.getCatalogItems('GetRepairFees');
  }


  /**
   * Get plans.
   * @returns Plans
   */
  async getPlans(): Promise<CatalogItem[]> {
    return this.getCatalogItems('GetPlans');
  }

  /**
   * Get catalog items.
   * @param action Controller action
   * @returns Collection of catalog items
   */
  private async getCatalogItems(action: string): Promise<CatalogItem[]> {
    return new Promise<CatalogItem[]>(resolve => {
      if (this.cache[action]) {
        resolve(this.cache[action].map(x => new CatalogItem(x)));
      } else {
        const url = `${this.controller}/${action}`;
        this.http.get<ICatalogItem[]>(url).subscribe({
          next: data => {
            if (data) {
              this.cache[action] = data;
              resolve(data.map(x => new CatalogItem(x)));
            } else {
              resolve([]);
            }
          },
          error: () => resolve([])
        })
      }
    });
  }
}

/**
 * Interface for catalog cache.
 */
interface IOrderPricingCache {
  [index: string]: ICatalogItem[];
}