<!-- dialog title -->
<h1 mat-dialog-title>
    <span>{{ title | translate}}</span>
    <button data-ef-button="fee-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<!-- dialog content -->
<div mat-dialog-content [innerHTML]="message | translate"></div>

<!-- dialog actions -->
<div mat-dialog-actions class="justify-content-between">

    <!-- cancel -->
    <button data-ef-button="confirm-cancel-button" mat-stroked-button color="alt"
        mat-dialog-close="cancel">{{'BUTTONS.CANCEL'|translate}}</button>

    <!-- confirm -->
    <button data-ef-button="confirm-confirm-button" mat-flat-button color="primary"
        mat-dialog-close="confirm">{{ buttonText |translate}}</button>

</div>