<!-- dialog title -->
<h1 mat-dialog-title>
  <span>{{ 'DIALOGS.CHANGE_OFFICE' | translate}}</span>
  <button data-ef-button="change-office-close" mat-icon-button [attr.aria-label]="'BUTTONS.CLOSE'" mat-dialog-close="close">
    <mat-icon>close</mat-icon>
  </button>
</h1>

<!-- dialog content -->
<div mat-dialog-content class="ms-5 me-5">
  <!-- warning message -->
  <pm-alert data-ef-error="change-office-warning" class="d-block mb-3" [options]="alertOptions">
    {{ alertMessage }}
  </pm-alert>
  <div class="pm-panel-above-table px-3 py-4" *ngIf="this.offices && this.offices.length > 10">
    <form class="align-items-center d-flex gap-3" [formGroup]="form" (keydown.enter)="$event.preventDefault()"
      *ngIf="form">

      <!-- search criteria -->
      <mat-form-field [hideRequiredMarker]="true" class="disable-error-outline flex-fill">
        <mat-label data-ef-label="change-office-search-label">{{ 'LABELS.OFFICE_SEARCH' | translate
          }}</mat-label>
        <input #searchInput data-ef-input="change-office-search-text" matInput cdkFocusInitial
          formControlName="searchText" [placeholder]="'PLACEHOLDERS.OFFICE_SEARCH'|translate" autocomplete="off"
          (keyup)="applyFilter($event)">
      </mat-form-field>
    </form>
  </div>

  <div class="pm-panel-table">
    <table data-ef-table="office-search-results" mat-table class="pm-table-default" [dataSource]="dataSource" matSort>

      <!-- Office Name Column -->
      <ng-container matColumnDef="OfficeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by office name"
          class="d-none d-md-table-cell">{{ 'TABLES.COLUMNS.OFFICE_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let office" class="d-none d-md-table-cell">{{ office.OfficeName }}</td>
      </ng-container>

      <!-- Office Number Column -->
      <ng-container matColumnDef="OfficeNum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by office number"
          class="d-none d-md-table-cell">{{ 'TABLES.COLUMNS.OFFICE_ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let office" class="d-none d-md-table-cell">
          {{ office.OfficeNum }}
        </td>
      </ng-container>

      <!-- Office Address Column -->
      <ng-container matColumnDef="OfficeAddress">
        <th mat-header-cell *matHeaderCellDef class="d-none d-md-table-cell">{{ 'TABLES.COLUMNS.OFFICE_ADDRESS' | translate }}</th>
        <td mat-cell *matCellDef="let office" class="d-none d-md-table-cell">{{ office.OfficeAddress }}</td>
      </ng-container>

      <!-- no data -->
      <tr class="mat-row no-data" role="row" *matNoDataRow>
        <td class="p-3 text-center" role="cell" [attr.colspan]="displayedColumns.length">
          {{ 'MESSAGES.CHANGE_LOCATION_NO_DATA' | translate }}
        </td>
      </tr>

      <!-- Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectOffice(row)"></tr>
    </table>
  </div>
  <!-- Paginator -->
  <mat-paginator [hidePageSize]="true" [pageSize]="10" [showFirstLastButtons]="true"
    [attr.aria-label]="'TABLES.PAGINATOR_LABEL'|translate" [hidden]="!isLoaded">
  </mat-paginator>

  <!-- template for loading spinner -->
  <div class="overlay" *ngIf="!isLoaded">
    <mat-spinner></mat-spinner>
  </div>

</div>