/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { ISearchResults } from '@pm/core';
import { Accessory } from '../classes/accessory';
import { Frame } from '../classes/frame';
import { FrameDetail } from '../classes/frame-detail';
import { AccessoryResultsMockData } from '../mock-data/accessory-results';
import { FrameResultsMockData } from '../mock-data/frame-results';
import { FramesMockData } from '../mock-data/frames';
import { CatalogService } from './catalog.service';

/**
 * Catalog service mock for testing.
 */
@Injectable()
export class CatalogMockService extends CatalogService {
    /**
       * Find accessories.
       * @param searchText Search text
       * @param skip Number of iutems to skip
       * @param take Number of items to take
       * @param sort Sort state
       * @returns Mock accessory search results
       */
    override async findAccessories(searchText: string | null, skip = 0, take = 10, sort = this.defaultAccessoriesSort): Promise<ISearchResults<Accessory>> {
        const data = AccessoryResultsMockData();
        return { Items: data.data.map(x => new Accessory(x)), TotalAvailable: data.recordsFiltered };
    }

    /**
     * Find frames.
     * @param searchText Search text
     * @param skip Number of iutems to skip
     * @param take Number of items to take
     * @param sort Sort state
     * @returns Mock frame search results
     */
    override async findFrames(searchText: string | null, active:boolean, skip = 0, take = 10, sort = this.defaultFramesSort): Promise<ISearchResults<Frame>> {
        const data = FrameResultsMockData();
        return { Items: data.data.map(x => new Frame(x)), TotalAvailable: data.recordsFiltered };
    }

    /**
     * Get frame detail.
     * @param itemId Item id
     * @returns Mock frame detail
     */
    override async getFrameDetail(itemId: number): Promise<FrameDetail | null> {
        const data = FramesMockData().find(x => x.ItemID === itemId);
        return data ? new FrameDetail(data) : null;
    }
}