/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent, IAlert } from '@pm/core/alerts';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogHelper } from '@pm/layout';
import { IOffice, OfficesService } from '@pm/core/companies';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppFeatureService } from '@pm/core/configuration';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from '@pm/core/security';

@Component({
  selector: 'pm-change-location-modal-component',
  templateUrl: './change-location.dialog.html',
  styleUrls: ['./change-location.dialog.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatIconModule,
    MatPaginatorModule,
    AlertComponent,
    MatButtonModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  providers: [LoginService]
})

/**
 * Dialog for changing the user's office location.
 */
export class ChangeLocationDialog implements OnInit {
  /** What columns to display in the grid */
  displayedColumns: string[] = ['OfficeName', 'OfficeNum', 'OfficeAddress']; // Add other column names as needed
  /** Are offices loaded? */
  public isLoaded = false;
  /** Material paginator. */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /** Material sort. */
  @ViewChild(MatSort) sort!: MatSort;
  /** Material table data source. */
  public dataSource!: MatTableDataSource<IOffice>;
  /** The controller for the API. */
  private controller = 'Resources';
  /** Helper class for dialogs. */
  private dialogHelper = new DialogHelper();
  /** Form group. */
  readonly form = new FormGroup({ searchText: new FormControl<string>('', Validators.required) });
  /** Offices assigned to source */
  offices: IOffice[] | null = null;
  /** Broadcast channel to post a reload message event */
  private broadcastChannel: BroadcastChannel;
  /** Custom alert message that will be shown on change location dialog */  
  public alertMessage: string;
  /** Alert options (Warning/Info) that will be shown on change location dialog */
  public alertOptions: IAlert = {type:'Warning'};

  /**
   * Constructor
   * 
   * @param officeLocationService  The service to get the employee's offices
   * @param dialogRef  The dialog reference
   * @param dialog  The dialog service
   * @param snackBar  The snack bar service
   * @param appFeatureService  The service to get the app features
   * @param translate  The translation service
   **/
  constructor(private officeLocationService: OfficesService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<ChangeLocationDialog>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: {infoMessage: string|null},
    private snackBar: MatSnackBar,
    private appFeatureService: AppFeatureService,
    private translate: TranslateService) {
    this.broadcastChannel = new BroadcastChannel('reload_channel');
    if(data.infoMessage == null)
    {      
      this.alertMessage = translate.instant('MESSAGES.CHANGE_OFFICE_WARNING');
    }
    else
    {
      this.alertMessage = data.infoMessage;
      this.alertOptions = {type: 'Info'};
    }
  }

  /**
   * Initialize the dialog
   */
  async ngOnInit(): Promise<void> {

    // Get a list of offices that are accessible for the logged in user
    this.offices = await this.officeLocationService.getEmployeeOffices();
    this.isLoaded = true;

    // If offices were returned, update the table data
    if (this.offices) {
      this.dataSource = new MatTableDataSource(this.offices);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * Apply a filter to the table
   *  
   * @param event  The event to filter the table
   */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Select an office from the table
   *  
   * @param selectedRow  The selected row from the table
   */
  async selectOffice(selectedRow: IOffice): Promise<void> {
    // User clicked "Yes", switch to the new office
    const result = await this.loginService.changeOffice(selectedRow.OfficeNum);

    if (!result) {
      console.error('Error changing office');
    }

    if (result) {
      switch (result.PasswordCheckResult) {
        case 'MeetsPolicy':
          this.snackBar.open(this.translate.instant('MESSAGES.CHANGED_OFFICES') as string, undefined, { panelClass: 'success-message' });
          
          // Post a message to the channel after a successful change to refresh open tabs
          this.broadcastChannel.postMessage('gohome');
          break;
      }
    }

    this.dialogRef.close();
  }
}
