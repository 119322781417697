import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Confirm dialog.
 */
@Component({
  selector: 'pm-confirm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss']
})
export class ConfirmDialog {
  /** Confirm message. */
  public message: string;
  /** Dialog title. */
  public title: string;
  /** Dialog Button text. */
  public buttonText:string;

  /**
   * Initialize instance of the ConfirmDialog class.
   * @param data Confirm dialog configuraion
   */
  constructor(@Inject(MAT_DIALOG_DATA) private data: IConfirmDialogData) {
    this.message = data.message;
    this.title = data.title ?? 'DIALOGS.CONFIRM';
    this.buttonText = data.buttonText ?? 'BUTTONS.CONFIRM'; 
  }
}

/**
 * Confirm dialog configuraion.
 */
export interface IConfirmDialogData {
  message: string;
  title?: string;
  buttonText?: string;
}