import { ILoginResponse } from '../interfaces/login-response';

export function LoginResponseMockData(): ILoginResponse {
    return {
        'Action': 'Verify',
        'EmergencyAccess': false,
        'IsValid': true,
        'LoginName': 'xxxxxx',
        'OfficeId': 184,
        'Password': 'xxxxxx',
        'OldPassword': null,
        'PasswordCheckResult': 'Verified',
        'PasswordPolicy': '- 1 uppercase character or 1 lowercase character <br/>- 1 numeric character<br/>',
        'PasswordPolicyLabel': 'Your password must be between 8 and 15 characters long and must contain:<br />',
        'IsVspIamPolicy': false,
        'PracticeLocationId': '936',
        'CompanyId': '936',
        'RememberMyUsername': false,
        'RememberOfficeNumber': false,
        'UserId': 0,
        'OverrideUserId': 121010,
        'RedirectUrl': '',
        'DayCloseInRole': true,
        'DayOpenInRole': true,
        'ChangePaymentTypesInRole': true,
        'IsEhrEnabled': true,
        'NumberOfAttempts': 0,
        'Note': null,
        'OfficeZipCode': null,
        'OfficePhoneNumber': null,
        'SecurityQuestion': 'What was your childhood nickname?',
        'SecurityAnswer': null,
        'NumberOfPasswordResetAttempts': 0,
        'IsOnPremEcrVault': true,
        'FrameDataMessage': 'Test Message',
        'IsChwEnabled': false,
        'IsBtnClickRequiredForPatientSearch': false,
        'IsTillEnabled': false,
        'IsVSPPremierDashboardEnabled': true,
        'IsPaymentAuthApi': true,
        'IsHideBillingInfoInFrontOfficeReportingEnabled': false,
        'IsScheduleApptforotherOfficesEnabled': true,
        'IsAnalyticsAndInsightsEnabled': true,
        'IsPriceTransparencyEnabled': false,
        'IsShowEPMFeatureConfigurationInALAdminEnabled': false,
        'IsNewPricingScreenEnabled': true,
        'IsClearinghouseClaimStatusIntegratedEnabled': false,
        'HideListPrice': true,
        'RequireOCHeight': false,
        'CombineBifocalSplitPD': true,
        'IsEncompassLocation': false,
        'Theme': null,
        'IsUserMigratedToUnifiedLogin': false,
        'IsPatientOverviewBannerEnabled': false,
        'ShowSelectOfficeDialog': false
    };
}